
import * as R from 'ramda';
// constants
import * as GC from '../constants';
// helpers
// import { toNumber } from './calc';
import { fromEmptyStringToNull, fromNullToEmptyString } from './string';
import { isTrue, isObject, notContain, isNilOrEmpty, isNotNilAndNotEmpty } from './helpers';
//////////////////////////////////////////////////

// Not used
// const filterWithKeys = (pred: Function, obj: Object) => R.pipe(
//   R.toPairs,
//   R.filter(R.apply(pred)),
//   R.fromPairs,
// )(obj);

const renameKeys = R.curry((keysMap: Object, obj: Object) => R.reduce(
  (acc: Object, key: string) => R.assoc(R.or(keysMap[key], key), obj[key], acc),
  {},
  R.keys(obj),
));

// Not used
// const toNumberObjectValues = (obj: Object) => R.reduce(
//   (acc: Object, key: string) => R.assoc(key, toNumber(obj[key]), acc),
//   {},
//   R.keys(obj),
// );

// Not used
// const indexToValue = R.curry((valueName: string, obj: Object) => {
//   const copy = Object.assign({}, obj);
//   const values = Object.values(copy);
//   const keys = Object.keys(copy);
//   const newObject = Object.assign({}, values[0]);

//   newObject[valueName] = keys[0];

//   return newObject;
// });

const checkPropNotEqualInObjects = (propName: string, obj1: Object, obj2: Object) => R.not(R.eqProps(
  propName,
  obj1,
  obj2,
));

const pickObjectSystemFields = (item: Object = {}) => R.pick(GC.GROUPED_FIELDS.SYSTEM_OMIT_ARR, item);

const pickNotNilAndNotEmptyPropsFromObject = (fields: Array, object: Object) => R.compose(
  R.pickBy(isNotNilAndNotEmpty),
  R.pick(fields),
)(object);

const omitObjectSystemFields = (item: Object) => R.omit(GC.GROUPED_FIELDS.SYSTEM_OMIT_ARR, item);

const omitObjectChildArrayItemSystemFields = (arrayName: string, object: Object) => {
  if (isNilOrEmpty(object)) return object;

  if (isNilOrEmpty(arrayName)) return object;

  const newArray = R.compose(
    R.map(R.omit(GC.GROUPED_FIELDS.SYSTEM_OMIT_ARR)),
    R.pathOr([], [arrayName]),
  )(object);

  return R.assoc(arrayName, newArray, object);
};

const omitObjectChildArrayItemSystemFieldsCurried = R.curry(omitObjectChildArrayItemSystemFields);

const createLabelValueOptionObject = (label: any = null, value: any = null) => ({ label, value });

const setStringValueInsteadObjectValueByPath = (obj: Object, path: Array, prop: string) => {
  if (isObject(R.path(path, obj))) {
    return R.assocPath(path, R.path([...path, prop], obj), obj);
  }

  return obj;
};

const filterAndJoinObjectFields = (obj: Object, fields: Array = [], joiner: string = ', ') => R.compose(
  R.join(joiner),
  R.filter(isNotNilAndNotEmpty),
  R.values,
  R.pick(fields),
)(obj);

const mapWithRenameKeys = R.curry((keysMap: Object, items: Array) => R.map(
  renameKeys(keysMap),
  items,
));

const mapObjectEmptyStringFieldsToNull = R.mapObjIndexed(
  (value: any) => fromEmptyStringToNull(value),
);

const mapObjectNullFieldsToEmptyStrings = R.mapObjIndexed(
  (value: any) => fromNullToEmptyString(value),
);

const mapNameGuidObjectPropsToLabelValueObject = R.map(
  ({ name, guid }: Object) => ({ label: name, value: guid }),
);

const mapUnitIdGuidObjectPropsToLabelValueObject = R.map(
  ({ guid, unitId }: Object) => ({ value: guid, label: unitId }),
);

const mapDisplayNameGuidObjectPropsToLabelValueObject = R.map(
  ({ guid, displayName }: Object) => ({ value: guid, label: displayName }),
);

const mapNameGuidToLabelValue = (items: Array) => {
  const keysMap = { name: 'label', guid: 'value' };

  return mapWithRenameKeys(keysMap, items);
};

const mapDisplayedValueDropdownOptionGuidToLabelValue = (items: Array) => {
  const keysMap = { displayedValue: 'label', dropdownOptionGuid: 'value' };

  return mapWithRenameKeys(keysMap, items);
};

// Not used
// const mapOptionsValueToGuids = R.map(
//   ({ value }: Object) => value,
// );

const mapObjectChildArrayItemEmptyStringFieldsToNull = (arrayName: string, object: Object) => {
  if (isNilOrEmpty(object)) return object;

  if (isNilOrEmpty(arrayName)) return object;

  const newArray = R.compose(
    R.map(fromEmptyStringToNull),
    R.pathOr([], [arrayName]),
  )(object);

  return R.assoc(arrayName, newArray, object);
};

const notInList = (list: Array, value: any) => (
  R.isNil(list) || notContain(value, list)
);

const sortListByDate = (list: Array = [], propName: string, descOrder: boolean) => {
  if (isTrue(descOrder)) {
    return list.sort((a: string, b: string) => new Date(b[propName]) - new Date(a[propName]));
  }

  return list.sort((a: string, b: string) => new Date(a[propName]) - new Date(b[propName]));
};

const setPropsWithValuesToArrayItems = (arr: Array, props: Array) => (
  R.map((item: Object) => R.mergeRight(
    item,
    R.mergeAll(R.map((prop: Object) => ({[prop.name]: prop.value}), props)),
  ), arr)
);

const getArrayFilteredFromArrayByProp = (arr1: Array, arr2: Array, prop: string) => {
  const checkProp = (a: Object, b: Object) => R.eqProps(prop, a, b);

  return R.differenceWith(checkProp, arr1, arr2);
};

const prependEmptyLabelValueOption = (list: Array = []) => R.prepend(GC.EMPTY_OPTION_OBJECT, list);

const transformArrayOfObjectsToStringByPropName = (arr: Array = [], joiner: any, propName: string) => R.compose(
  R.join(joiner),
  R.filter(isNotNilAndNotEmpty),
  R.pluck(propName),
)(arr);

const mapArrayObjectEmptyStringFieldsToNull = (array: Array) => {
  if (isNilOrEmpty(array)) return [];

  return R.map(mapObjectEmptyStringFieldsToNull, array);
};

const mapArrayOfObjectsToPropArray = (propName: string = '', items: Array = []) => (
  R.map((item: Object) => R.prop(propName, item), items)
);

const mapOmitObjectSystemFields = R.map(omitObjectSystemFields);

const incObjectVersion = (obj: Object) => {
  if (isNilOrEmpty(obj)) return obj;

  const { version } = obj;

  if (isNilOrEmpty(version)) return obj;

  return R.assoc('version', R.inc(version), obj);
};

const incEventLocationContactVersions = (event: Object) => {
  const { location } = event;

  const contacts = R.pathOr([], ['contacts'], location);
  const newContacts = R.map((contact: Object) => incObjectVersion(contact), contacts);
  const newLocation = incObjectVersion(location);
  const newLocationWithContacts = R.assoc('contacts', newContacts, newLocation);
  const newEvent = R.assoc('location', newLocationWithContacts, event);

  return incObjectVersion(newEvent);
};

// helper for creating new Array from object after handling object by function
const getNewArrayFromObjectWithHandling = (handler: Function, object: Object) => (
  R.values(R.mapObjIndexed(handler, object))
);

// Not used
// helper for creating new object from object with same properties but boolean values
// const getNewObjectWithBoolValues = (object: Object, value: boolean) => (
//   R.map(() => value, object)
// );

// get options from data array
const getOptionsFromDataArrayByPath = (pathToLabel: Array, pathToValue: Array, data: Array = []) => R.map(
  (item: Object) => ({
    label: R.path(pathToLabel, item),
    value: R.path(pathToValue, item),
  }),
  data,
);

export {
  notInList,
  renameKeys,
  // indexToValue,
  // filterWithKeys,
  sortListByDate,
  incObjectVersion,
  mapWithRenameKeys,
  // toNumberObjectValues,
  pickObjectSystemFields,
  omitObjectSystemFields,
  // mapOptionsValueToGuids,
  mapNameGuidToLabelValue,
  filterAndJoinObjectFields,
  mapOmitObjectSystemFields,
  // getNewObjectWithBoolValues,
  checkPropNotEqualInObjects,
  createLabelValueOptionObject,
  mapArrayOfObjectsToPropArray,
  prependEmptyLabelValueOption,
  getOptionsFromDataArrayByPath,
  setPropsWithValuesToArrayItems,
  incEventLocationContactVersions,
  getArrayFilteredFromArrayByProp,
  mapObjectEmptyStringFieldsToNull,
  getNewArrayFromObjectWithHandling,
  mapObjectNullFieldsToEmptyStrings,
  pickNotNilAndNotEmptyPropsFromObject,
  omitObjectChildArrayItemSystemFields,
  mapArrayObjectEmptyStringFieldsToNull,
  setStringValueInsteadObjectValueByPath,
  mapNameGuidObjectPropsToLabelValueObject,
  transformArrayOfObjectsToStringByPropName,
  mapUnitIdGuidObjectPropsToLabelValueObject,
  omitObjectChildArrayItemSystemFieldsCurried,
  mapObjectChildArrayItemEmptyStringFieldsToNull,
  mapDisplayNameGuidObjectPropsToLabelValueObject,
  mapDisplayedValueDropdownOptionGuidToLabelValue,
};
